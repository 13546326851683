import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ToggleSwitch from "../../common/ToggleSwitch";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { PointService } from "../../../services";
import { toast } from "react-toastify";
import ConfirmationModal from "../../common/ConfirmationModal";
import { IfService } from "../../../services/IfService";
import secureStorage from '../../../config/encrypt';

const TargetListTabs = (props) => {
  let { targetDetails, loading , setLoading} = props;
  const authUserInfo = secureStorage.getItem('authUserInfo') || {}; 
  const userId = authUserInfo?.user_data?.id || 1;

  const [state, setState] = useState({
    areaExpandedParent: false,
    areaExpandedChild: false,
    DeleteIncentivepopup: false,
    ModifyStatusPopup: false,
    CancelIncentivepopup: false,
    element_selected: {},
    index: ''

  });


  const showModalpopup = (value, element, index, showExpiredToggle = false) => {
    if (!element.is_expired || showExpiredToggle) {
      let duplicatePointConfig = false;
      if (showExpiredToggle && element["user_role"] && element["point_duration"] && element["product_type"] && props.activeConfigList && props.activeConfigList.length > 0) {
        duplicatePointConfig = props.activeConfigList.some((list) => {
          return ((list.user_role === element["user_role"]) && (list.point_duration === element["point_duration"]) && (list.product_type === element["product_type"]));
        });
      }
      if (duplicatePointConfig) {
        toast.error("You can not activate this Configuration as it already exists")
      } else {
        setState({ element_selected: element, index: index, [value]: true });
        document.body.classList.add("overflow-hidden");
      }
    }
  };

  /*
  * Delete point Config
  */
  const confirmationModalResponse = (response) => {
    setState({ DeleteIncentivepopup: false }); 
    if (response.response) {
      if (response.type.csv_log_id) {
        setLoading(true);
        IfService.updateTargetStatus({
          csv_log_id: response.type.csv_log_id,
          is_deleted: response.type.is_deleted ? 0 : 1,
          user_ids: [userId],
          month: response?.type?.data?.[0].month
        }).then((res) => { 
          res = res.data;
          setLoading(false);
          if (res?.status === 200) { 
              toast.success(res?.message);
              props.getTargetListData({});
          } else { 
              toast.error(res?.message);
          }
      }).catch(err => { 
          toast.error(err);
          setLoading(false);
      });
      } 
    }
    document.body.classList.remove("overflow-hidden");
  }

  
  const activeModalResponse = (response) => {
    setState({ ModifyStatusPopup: false });  
    if (response.response) {
      if (response.type.csv_log_id) {  
        setLoading(true);
        IfService.updateTargetStatus({
          csv_log_id: response.type.csv_log_id,
          is_active: response.type.is_active ? 0 : 1,
          user_ids: [userId],
          month: response?.type?.data?.[0].month
        }).then((res) => {
          res = res.data;
          setLoading(false);
          if (res?.status === 200) { 
              toast.success(res?.message);
              props.getTargetListData({});
          } else { 
              toast.error(res?.message);
          }
      }).catch(err => { 
          toast.error(err);
          setLoading(false);
      });
      }
      // props.handleChange("is_active", !response.type.is_active, '', state.index);
      setState({ index: '' });
    }
    document.body.classList.remove("overflow-hidden");
  }
 

  const handleSubParentClicks = (e, childRefUUid) => {
    e.stopPropagation();
    //ON TAB CLICK OPEN/CLOSE HANDLER
    if (typeof e.target.className == 'string' && e.target.className.indexOf("accordion__button") !== -1) {
      if (e.target["ariaExpanded"] === "true") {
        props.showSubExpandedTabUuid(-1);
        setState({ areaExpandedChild: false });
      } else {
        props.showSubExpandedTabUuid(childRefUUid);
      }
    }

    //ON HEADING CLICK OPEN/CLOSE HANDLER
    if (
      e.target.attributes &&
      e.target.attributes.accordionstatus &&
      e.target.attributes.accordionstatus.nodeValue
    ) {
      if (e.target.attributes.accordionstatus.nodeValue === "open") {
        props.showSubExpandedTabUuid(-1);
        setState({ areaExpandedChild: false });
      } else {
        props.showSubExpandedTabUuid(childRefUUid);
      }
    }
  };

  const handleNestedClicks = (e) => {
    e.stopPropagation();
  };

  const checkforDisable = (data) => {
    if (data?.data.length) {
        let csvLogMonth = data.data[0].month
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        const csvLogMonthDate = new Date(csvLogMonth);
        const csvLogMonthYear = csvLogMonthDate.getFullYear();
        const csvLogMonthMonth = csvLogMonthDate.getMonth();

        const prevMonth = currentMonth - 1 >= 0 ? currentMonth - 1 : 11;
        const prevYear = currentMonth - 1 >= 0 ? currentYear : currentYear - 1;

        if (csvLogMonthYear < prevYear || (csvLogMonthYear === prevYear && csvLogMonthMonth < prevMonth)) {
          return true;
        }
    }
    return false;
  }


 
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div  className="sub-accordion">
        <Accordion allowZeroExpanded="true">
                  {targetDetails && targetDetails.length && targetDetails.map((data, index) => { 
                    return ( 
                      <AccordionItem
                      key={ index} 
                      uuid={data.csv_log_id} 
                      onClick={(e) => {
                        handleSubParentClicks(e,index + "__" + data.csv_log_id)
                      }}
                      dangerouslySetExpanded={
                        props.subExpandedTabs === index + "__" + data.csv_log_id || state.areaExpandedChild
                      }
                      >

                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <h2 accordionstatus={props.subExpandedTabs === index + "__" + data.csv_log_id ? "open": "close"}>
                              User Level Targets ({data?.data?.length})
                            </h2>
                            <ul className="right-action-item" onClick={handleNestedClicks}>
                              <div className="on-off-switch">
                                {
                                  <ToggleSwitch
                                    status={data.is_active} 
                                    onChange={() => { showModalpopup("ModifyStatusPopup", data, index); }}
                                    disabled = {checkforDisable(data)}
                                  />
                                }
                              </div>
                              {
                                (!checkforDisable(data))
                                  ? <li onClick={() => { showModalpopup("DeleteIncentivepopup", data, index); }}><i className="ic-delete-icn"></i></li>
                                  : <li><i className="ic-delete-icn"></i></li>
                              }
                            </ul>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="p-lg-t">
                            <div className="">
                              <div className="data-table bg-white">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>SFA ID</th>
                                      <th>User Name</th>
                                      <th>Month</th>
                                      <th>Business Line</th>
                                      <th>Role</th>
                                      <th>Disbursal Target</th>
                                      <th>{"Onboarding > 200"}</th>
                                      <th>{"Onboarding <= 200"}</th>
                                      <th>AUM Exist</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      data.data?.length ?
                                        data.data.map((target, index) => (
                                          <tr className={"active"} key={index}>
                                            <td>{target.user_id}</td>
                                            <td> <span className="truncate" title={target.name}>{target.name}</span></td>
                                            <td>{target.month}</td>
                                            <td>{target.product_type}</td>
                                            <td>{target.role}</td>
                                            <td>{target.disbursal_target}</td>
                                            <td>{target.onboard_1}</td>
                                            <td>{target.onboard_2}</td>
                                            <td>{target.aum_exist || "-"}</td>
                                          </tr>
                                        ))
                                        : (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={6} className="no-data"> {'No data found.'}</td></tr>

                                    }
                                  </tbody>
                                </table>
                              </div> 
                            </div>

                          </div>

                        </AccordionItemPanel>
                      </AccordionItem>
                    )
                  })}
                </Accordion>
        </div>
    
      </div>

      {state.DeleteIncentivepopup ? (
          <ConfirmationModal
            confirmationData={{
              show: state.DeleteIncentivepopup,
              confirmationText: props.t("POINT_CONFIG_DETAIL.MESSAGE.DELETE_CONFIG"),
              type:state.element_selected,
            }}
            confirmationModalResponse={(data) =>
              confirmationModalResponse(data)
            }
          />
        ) : null}
 

        {state.ModifyStatusPopup ? (
          <ConfirmationModal
            confirmationData={{
              show: state.ModifyStatusPopup,
              confirmationText:state.element_selected.is_active
                ? props.t('IF.INACTIVE_TARGET_LIST')
                : props.t("IF.ACTIVE_TARGET_LIST"),
              type:state.element_selected,
            }}
            confirmationModalResponse={(data) =>
              activeModalResponse(data)
            }
          />
        ) : null}
    </React.Fragment>
  );

}
const mapStateToProps = (state, ownProps) => {
  return {
    userInfo: (state.auth.authUser) ? state.auth.authUser.user_data : {}
  };
}
export default withTranslation('common')(connect(mapStateToProps)(TargetListTabs)); 
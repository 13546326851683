import React from 'react';
import {AccordionItemPanel} from 'react-accessible-accordion';
import { useTranslation } from "react-i18next";
import DescriptionCard from "../../cards/DescriptionCard"
import BasicDetails from "../cards/BasicDetails"
import IfDisbursalCard from "../cards/IfDisbursalCard"

const IfAccordionItemPanel = (props) => {
    let { element, tempErrors, incentive_config, element_index, loading, addMoreIncentive,removeMoreIncentive } = props;
    const { t } = useTranslation("common");
    //const [description, setDescription] = useState('');

    const handleUpdate = (element) => {
        props.handleUpdate(element)
    }

    return (
        <React.Fragment>
            <AccordionItemPanel>
                <h3 className="h3">{t('COMMON.BASIC_DETAIL')} </h3>
                <div className={element.form_config.BASIC_DATE_FILTER ? 'basic-top-filter-outer' : ""}>
                    {Object.keys(incentive_config).length > 0 &&
                        <BasicDetails element={element} incentive_config={incentive_config} onChange={(e, i, q, u) => { props.handleChange(e, i, q, u) }} tempErrors={tempErrors} {...props} />
                    }
                </div>
                <div className='left-right-panel-outer'>
                    <div className='add-incenative-left-panel if-disbursal-panel'>

                        {(element.form_config.FRESH_DISBURSAL_CARD || element.form_config.DEALER_ACTIVATED_CARD || element.form_config.RENEWAL_REPAYMENT_CARD || element.form_config.ACTIVATED_ONBOARD_CARD || element.form_config.ADDITION_INC_CARD || element.form_config.AGEING_CLOSED_LOAN || element.form_config.COMPLETE_COLLDOC || element.form_config.PROFIT_SHARING_1 || element.form_config.PROFIT_SHARING_2 || element.form_config.AUM_EXIT) &&
                            <IfDisbursalCard 
                            incentive_config={incentive_config} 
                            element={element} 
                            onChange={(e, i, q, u) => {props.handleChange(e, i, q, u) }} 
                            minMaxDate={props.minMaxDate}
                            tempErrors={tempErrors} 
                            addMoreIncentive = {addMoreIncentive} 
                            element_index={element_index} 
                            removeMoreIncentive = {removeMoreIncentive} 
                            element_key={element.form_config.KEY} 
                            />
                        }

                        <DescriptionCard element={element} onChange={(e, i, q, u) => {props.handleChange(e, i, q, u) }} onUpdate={() => { handleUpdate(element) }} onCancel={() => {props.handleCancel(element) }} loading={loading} />

                        <div className='incentive-login-msg'>
                            <i className='ic-info-icon1'></i>
                            {t('FORM.NOTES')}
                        </div>


                    </div>
                </div>
            </AccordionItemPanel>
        </React.Fragment>
    );

}

export default IfAccordionItemPanel;
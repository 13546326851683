const formValidation = (data,t) => { 
    let formIsValid = true;
    let tempErrors = {}; 
    
    if (!data["product_type"]) {
        formIsValid = false;
        tempErrors["product_type"] = t('VALIDATION.PRODUCT_TYPE');
    }
    if (!data["scheme_duration"]) {
        formIsValid = false;
        tempErrors["scheme_duration"] = t('VALIDATION.SCHEME_DURATION');
    }
    if (!data["applicable_to"]) {
        formIsValid = false;
        tempErrors["applicable_to"] = t('VALIDATION.APPLICATION_TO');
    }
    if (data.form_config.FRESH_DISBURSAL_CARD || data.form_config.DEALER_ACTIVATED_CARD || data.form_config.RENEWAL_REPAYMENT_CARD || data.form_config.ACTIVATED_ONBOARD_CARD || data.form_config.AGEING_CLOSED_LOAN || data.form_config.PROFIT_SHARING_1 || data.form_config.AUM_EXIT){
        data[data.form_config.KEY].forEach((element, index) => {
            if (element.min_disb_per === "") {
                formIsValid = false;
                tempErrors["min_disb_per_" + index] = data.form_config.AGEING_CLOSED_LOAN ? t('IF.VALIDATION.MIN_LOAN_TENURE') : (data.form_config.PROFIT_SHARING_1 ? t('IF.VALIDATION.MIN_INTEREST') : t('IF.VALIDATION.MIN_DISB_ACH'));
            }
            if (element.max_disb_per === "") {
                formIsValid = false;
                tempErrors["max_disb_per_" + index] = data.form_config.AGEING_CLOSED_LOAN ? t('IF.VALIDATION.MAX_LOAN_TENURE') : (data.form_config.PROFIT_SHARING_1 ? t('IF.VALIDATION.MAX_INTEREST') : t('IF.VALIDATION.MAX_DISB_ACH'));
            }
            if (element.incentive_amount === "") {
                formIsValid = false;
                tempErrors["incentive_amount_" + index] =  t('IF.VALIDATION.INC_AMT');
            }
            
        });
    }
    if (data.form_config.ADDITION_INC_CARD){
        data[data.form_config.KEY].forEach((element, index) => {
            if (element.target_amount === "") {
                formIsValid = false;
                tempErrors["target_amount_" + index] = t('IF.VALIDATION.TARGET_ACH');
            }
            if (element.if_date === "") {
                formIsValid = false;
                tempErrors["if_date_" + index] = t('IF.VALIDATION.DATE');
            }
            if (element.incentive_amount === "") {
                formIsValid = false;
                tempErrors["incentive_amount_" + index] =  t('IF.VALIDATION.INC_AMT');
            }
            
        });
    }
    if (data.form_config.ACTIVATED_ONBOARD_CARD){
        data[data.form_config.KEY].forEach((element, index) => {
            if (element.operator === "") {
                formIsValid = false;
                tempErrors["operator_" + index] = t('IF.VALIDATION.OPERATOR');
            }
            if (element.onboard_limit === "") {
                formIsValid = false;
                tempErrors["onboard_limit_" + index] = t('IF.VALIDATION.ONBOARD');
            }
            if (element.incentive_amount === "") {
                formIsValid = false;
                tempErrors["incentive_amount_" + index] =  t('IF.VALIDATION.INC_AMT');
            }
            
        });
    }

    if (data.form_config.COMPLETE_COLLDOC){
        data[data.form_config.KEY].forEach((element, index) => {
       
            if (element.min_colldoc === "") {
                formIsValid = false;
                tempErrors["min_colldoc_" + index] =  t('IF.VALIDATION.MIN_COLLDOC');
            }
            if (element.incentive_amount === "") {
                formIsValid = false;
                tempErrors["incentive_amount_" + index] =  t('IF.VALIDATION.INC_AMT');
            } 
            if (element.max_colldoc === "") {
                formIsValid = false;
                tempErrors["max_colldoc_" + index] =  t('IF.VALIDATION.MAX_COLLDOC');
            }
            
        }); 
        if (data.min_onboarded === "" || data.min_onboarded == null) {
            formIsValid = false;
            tempErrors["min_onboarded"] = t('IF.VALIDATION.MIN_ONBOARD');
        }
        if (data.min_activation === "" || data.min_activation == null) {
            formIsValid = false;
            tempErrors["min_activation"] = t('IF.VALIDATION.MIN_ACTIVATION');
        } 
    }

    if (data.form_config.PROFIT_SHARING_2){
        data[data.form_config.KEY].forEach((element, index) => {
            if (element.target_amount === "") {
                formIsValid = false;
                tempErrors["target_amount_" + index] = t('IF.VALIDATION.PENALTY_AMT');
            } 
            
        });
    }



    return {tempErrors, formIsValid};
};




export default formValidation;
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonActions } from "../../../store/action";
import * as HELPER from '../../../config/helper';
import FullPageLoader from "../../common/FullPageLoader";
import { useTranslation } from "react-i18next";


const InventoryViewDetail = (props) => {
    const [isMounted, setIsMounted] = useState(false);
    const [urlQueryParams, setUrlQueryParams] = useState({});
    const [ifDetails, setIfDetails] = useState({});
    const [breakupIncentive, setBreakupIncentive] = useState([]);
    const [totalIncentive, setTotalIncentive] = useState([]);
    const [loading, setLoading] = useState(false);
    let { search } = useLocation();
    const history = useNavigate();
    const queryParams = new URLSearchParams(search);
    const { t } = useTranslation("common");



    // All PAYOUT DETAILS Data
    useEffect(() => { 
        if (!queryParams.get('id')) {
            history("/not-found");
            return false;

        } else {
            setIsMounted(true);
            getUrlParams()

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * GET QUERY STRING URL PARAMS
     */
    const getUrlParams = () => { 
        let queryParamsObj = {};
        let paramsObj = {};
        //LOOP THROUGH URL PARAMS
        for (const [urlParamKey, value] of queryParams) {
            if (urlParamKey === 'id') {
                paramsObj['id'] = Number(value)
            } 
            queryParamsObj[urlParamKey] = value;
        }
        //SET URL PARAMS OBJ & CALL SERVICE
        setUrlQueryParams(queryParamsObj)
        componentDidMount(paramsObj)

    }

    const componentDidMount = (filters = {}) => {
        let updatedSeacrFilter = Object.assign({}, filters);

        getIfPayoutDetails(updatedSeacrFilter)
    }

    const getIfPayoutDetails = (param = {}) => {
        setLoading(true);
        CommonActions.ifPayoutViewDetails(param).then(res => {
            setLoading(false)
            if (res.status === 200 && res.data && Object.keys(res.data).length) {
                setIfDetails(res.data);
                if (res.data?.total_incentive) {
                    setDataForTotalAmount(res.data?.total_incentive);
                   
                } 
                if (res.data?.breakup_incentive) {
                    let sortedData = res.data.breakup_incentive?.sort((a, b) => a.scheme_type_id - b.scheme_type_id);
                    setBreakupIncentive(sortedData)
                } 
            } else {
                setIfDetails(res.data);
            }
        });

    };

    const setDataForTotalAmount = (data) => { 

        const result = data.reduce((acc, curr) => {
            if (+curr.scheme_type_id === 2) { 
              const existing = acc.find(item => +item.scheme_type_id === 2);
              if (existing) { 
                existing.amount += curr.amount;
              } else { 
                acc.push({ ...curr });
              }
            } else { 
              acc.push(curr);
            }
            return acc;
          }, []);

        setTotalIncentive(result); 
    }

    const renderCardContent = (scheme) => { 
        switch (scheme?.scheme_type_id) {
            case 1:
                return (
                    <ul>
                        <li>
                            <label>Target</label>
                            <span>{scheme.target}</span>
                        </li>
                        <li>
                            <label>Actual Eligible Disbursement</label>
                            <span>{scheme.achieve_target}</span>
                        </li>
                        <li>
                            <label>% Achieve</label>
                            <span>{scheme.achieve_per}</span>
                        </li>
                        <li>
                            <label>GMV/Loan</label>
                            <span>{scheme.achieve_target}</span>
                        </li>
                        <li>
                            <label>Fresh Disbursals</label>
                            <span>{scheme.amount}</span>
                        </li>
                    </ul>
                );
            case 2:
                return (
                    <ul> 
                        {scheme?.headers.map((obj, index) =>
                            Object.entries(obj).map(([key, value]) => (
                                <li key={index}>
                                    <label>{key}</label>
                                    <span>{value}</span>
                                </li>
                            )))} 
                        <li>
                            <label>Total Incentive</label>
                            <span>{scheme.total_amount}</span>
                        </li>
                    </ul>
                );
            case 3:
                return (
                    <ul> 
                        <li>
                            <label>Dealer Activated New Onboard</label>
                            <span>Count</span>
                            <span>Amount</span>
                        </li>
                        <li>
                            <label>{t("IF.FORM.ONBOARD_MIN")}</label>
                            <span>{scheme.onboard_1_count}</span>
                            <span>{scheme.onboard_1_amount}</span>
                        </li>
                        <li>
                            <label>{t("IF.FORM.ONBOARD_MAX")}</label>
                            <span>{scheme.onboard_2_count}</span>
                            <span>{scheme.onboard_2_amount}</span>
                        </li>
                        <li>
                            <label>Incentive Amount</label>
                            <span>{scheme.achieve_target}</span>
                            <span>{scheme.amount}</span>
                        </li>
                    </ul>
                );
            case 4:
                return (
                    <ul>
                        <li>
                            <label>Mapped Dealer</label>
                            <span>{scheme.target}</span>
                        </li>
                        <li>
                            <label>Active Dealers</label>
                            <span>{scheme.achieve_target}</span>
                        </li>
                        <li>
                            <label>%Activation</label>
                            <span>{scheme.achieve_per}</span>
                        </li>
                        <li>
                            <label>Amount Dealer Activated</label>
                            <span>{scheme.amount}</span>
                        </li>
                    </ul>
                );
            case 5:
                return (
                    <ul>
                        <li>
                            <label>Open Loan(Expected to be repaid/renewed this month)</label>
                            <span>{scheme.target}</span>
                        </li>
                        <li>
                            <label>Renewed/Repaid</label>
                            <span>{scheme.achieve_target}</span>
                        </li>
                        <li>
                            <label>% Paid</label>
                            <span>{scheme.achieve_per}</span>
                        </li>
                        <li>
                            <label>Incentive Amount</label>
                            <span>{scheme.amount}</span>
                        </li>
                    </ul>
                );

            case 6:
                return (
                    <ul>
                    <li>
                        <label>Closed Loan (exclude 90+ days) </label>
                        <span>{scheme.achieve_target}</span>
                    </li>
                    <li>
                        <label>Average Closed Loan Ageing Tenure(exclude 90+ days)</label>
                        <span>{scheme.avg_loan_age}</span>
                    </li> 
                    <li>
                        <label>Incentive Amount</label>
                        <span>{scheme.amount}</span>
                    </li>
                    </ul>
                );

            case 7:
                return (
                    <ul>
                        <li>
                            <label>Colldoc completed and valid</label>
                            <span>{scheme.achieve_target}</span>
                        </li>
                        <li>
                            <label>New Dealer Onboard</label>
                            <span>{scheme.mapped_dealer}</span>
                        </li> 
                        <li>
                            <label>New Dealer Activated</label>
                            <span>{scheme.active_dealer}</span>
                        </li>
                        <li>
                            <label>Incentive Amount</label>
                            <span>{scheme.amount}</span>
                        </li>
                    </ul>
                );

            case 8:
                return (
                    <ul>
                        <li>
                            <label>New Dealer Onboarded</label>
                            <span>{scheme.target}</span>
                        </li>
                        <li>
                            <label>Eligible Dealer Onboarded</label>
                            <span>{scheme.achieve_target}</span>
                        </li> 
                        <li>
                            <label>Incentive Amount</label>
                            <span>{scheme.amount}</span>
                        </li>
                    </ul>
                );
            case 9:
                return (
                    <ul>
                        <li>
                            <label>Overdue transaction is paid without any deviation</label>
                            <span>{scheme.target}</span>
                        </li>
                        <li>
                            <label>Eligible</label>
                            <span>{scheme.achieve_target}</span>
                        </li> 
                        <li>
                            <label>Incentive Amount</label>
                            <span>{scheme.amount}</span>
                        </li>
                    </ul>
                );
            case 10:
                return (
                    <ul>
                        <li>
                            <label>Target (Mio)</label>
                            <span>{scheme.target}</span>
                        </li>
                        <li>
                            <label>Achieved (Mio)</label>
                            <span>{scheme.achieve_target}</span>
                        </li> 
                        <li>
                            <label>Achieved %</label>
                            <span>{scheme.achieve_per}</span>
                        </li>
                        <li>
                            <label>Incentive Amount</label>
                            <span>{scheme.amount}</span>
                        </li>
                    </ul>
                );
            default:
                return <ul><li>No data available for this scheme type</li></ul>;
        }
    };

    return ( 
        <div>
            <FullPageLoader show={loading} />
            <div className="container-fluid">
                <div className='p-lg-t'>
                    <h1>Business Manager IF - IncentiveStructure</h1>
                    <span>{`${urlQueryParams?.role} - ${urlQueryParams?.user_name} (${urlQueryParams?.user_id})`}</span>
                </div>
                <div className='total-incetive-outer'>
                    { !loading && 
                        <div>
                            <h2>Total Incentive</h2>
                            <div className='total-incentive-card-outer'>
                                {totalIncentive?.length > 0 ? totalIncentive.map((scheme, idx) => (
                                    <div className='total-incentive-card' key={scheme.scheme_id}>
                                        <label>{HELPER.addCurrencyFormatting(scheme.amount ? scheme.amount.toLocaleString() : 0)}</label>
                                        <span>{scheme.scheme_name}</span>
                                    </div>
                                )) : <div className="no-data"> No Data</div>
                                }
                            </div>
                        </div>
                    }

                    { !loading && 
                        <div>
                            <h2>Incentive Breakups</h2>
                            {breakupIncentive?.length > 0 ?
                                <div className='incentive-breakups-card-outer'>
                                    <div className='row'>
                                        {breakupIncentive.map((scheme, idx) => (
                                            <div className='col-md-4'>
                                                <div className='incentive-breakups-card'>
                                                    <div className='card-heading'>
                                                        {scheme.scheme_type_name}
                                                    </div>
                                                    {renderCardContent(scheme)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : <div className="no-data"> No Data</div>
                            }
                        </div>
                    }
                </div>

            </div>
        </div> 
    )
}


export default InventoryViewDetail;